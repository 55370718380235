<template>
  <transition name="fade">
    <div class="menu">
      <div class="menu__header">
        <img
          class="menu__logo"
          :src="require(`@/assets/img/${getUserTheme === 'dark-theme' ?  'menu-logo-w.svg' : 'menu-logo-b.svg' }`)"
          alt="INCHKYIV"
        >
        <div class="menu__controls">
          <div class="menu__langs">
            <LangsSelect></LangsSelect>
          </div>
          <div class="menu__theme-toggle">
            <ThemeToggle></ThemeToggle>
          </div>
          <div class="menu__close cursor-hover" @click="$emit('handleOpeningMenu', false)">
            <svg :class="{rotated: getOpenMenu}" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 14L27 14" stroke="black" stroke-linecap="square"/>
              <path d="M14 1L14 27" stroke="black" stroke-linecap="square"/>
            </svg>
            <p class="menu__close--text">{{ $t('header.close') }}</p>
          </div>
        </div>
      </div>
      <div class="menu__content">
        <nav class="menu__left">
          <ul class="menu__list">
            <li class="menu__item biggest-cursor-hover">
              <span class="menu__item--before">{{ $t('menu.right2') }}</span>
              <router-link
                to="/"
                @click.native="handleCurrentPage('home')"
                class="menu__item--text "
                :class="{ activeMenuItem: getCurrentPage === 'home' }"
              >
                {{ $t('menu.agency') }}
              </router-link>
            </li>
            <li class="menu__item biggest-cursor-hover">
              <span class="menu__item--before">{{ $t('menu.right') }}</span>
              <router-link
                to="/cases"
                @click.native="handleCurrentPage('cases')"
                class="menu__item--text "
                :class="{ activeMenuItem: getCurrentPage === 'cases' }"
              >
                {{ $t('menu.cases') }}
              </router-link>
            </li>
            <li class="menu__item biggest-cursor-hover">
              <span class="menu__item--before">{{ $t('menu.right') }}</span>
              <router-link
                to="/services"
                @click.native="handleCurrentPage('services')"
                class="menu__item--text "
                :class="{ activeMenuItem: getCurrentPage === 'services' }"
              >
                {{ $t('menu.services') }}
              </router-link>
            </li>
            <li class="menu__item">
              <a
                href="/INCH360_VR_platform_presentation_EN.pdf"
                target="_blank"
                class="menu__item--text biggest-cursor-hover"
              >
                <nobr class="biggest-cursor-hover">{{ $t('menu.inch360') }}</nobr>
              </a>
              <a
                href="/INCH360_VR_platform_presentation_EN.pdf"
                target="_blank"
                class="menu__item--after"
                :class="{ activeMenuItem: getCurrentPage === 'vr-platform' }"
              >
                {{ $t('menu.vrPlatform') }}
              </a>
            </li>

            <li class="menu__item">
              <a
                href="https://speechki.app/"
                target="_blank"
                class="menu__item--text biggest-cursor-hover"
              >
                <nobr class="biggest-cursor-hover">{{ $t('menu.speechki') }}</nobr>
              </a>
              <a
                href="https://speechki.app/"
                target="_blank"
                class="menu__item--after"
              >
                {{ $t('menu.speechkiAR') }}
              </a>
            </li>

            <li class="menu__item biggest-cursor-hover contact">
              <router-link
                to="/contact"
                @click.native="handleCurrentPage('contact')"
                class="menu__item--text "
                :class="{ activeMenuItem: getCurrentPage === 'contact' }"
              >
                {{ $t('menu.getInTouch') }}

                <svg class="menu__item--icon desc" width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M36.3581 53.3604L36.3581 52.9276L35.9253 52.9276L35.9253 53.3604L36.3581 53.3604ZM59.3855 53.3604L59.3855 53.7932L60.4305 53.7932L59.6916 53.0543L59.3855 53.3604ZM36.3581 63.7485L35.9253 63.7485L35.9253 64.1813L36.3581 64.1813L36.3581 63.7485ZM59.3845 63.7485L59.6906 64.0545L60.4295 63.3156L59.3845 63.3156L59.3845 63.7485ZM51.0456 72.0874L50.7395 71.7814L50.4334 72.0874L50.7395 72.3935L51.0456 72.0874ZM58.391 79.4329L58.085 79.7389L58.391 80.045L58.6971 79.7389L58.391 79.4329ZM79.27 58.5539L79.576 58.86L79.8821 58.5539L79.576 58.2479L79.27 58.5539ZM57.4765 36.7605L57.7826 36.4544L57.4765 36.1484L57.1705 36.4544L57.4765 36.7605ZM50.1311 44.1059L49.825 43.7999L49.5189 44.1059L49.825 44.412L50.1311 44.1059ZM36.3581 53.7932L59.3855 53.7932L59.3855 52.9276L36.3581 52.9276L36.3581 53.7932ZM36.7909 63.7485L36.7909 53.3604L35.9253 53.3604L35.9253 63.7485L36.7909 63.7485ZM59.3845 63.3156L36.3581 63.3156L36.3581 64.1813L59.3845 64.1813L59.3845 63.3156ZM51.3516 72.3935L59.6906 64.0545L59.0784 63.4424L50.7395 71.7814L51.3516 72.3935ZM58.6971 79.1268L51.3516 71.7814L50.7395 72.3935L58.085 79.7389L58.6971 79.1268ZM78.9639 58.2479L58.085 79.1268L58.6971 79.7389L79.576 58.86L78.9639 58.2479ZM57.1705 37.0665L78.9639 58.86L79.576 58.2479L57.7826 36.4544L57.1705 37.0665ZM50.4371 44.412L57.7826 37.0665L57.1705 36.4544L49.825 43.7999L50.4371 44.412ZM59.6916 53.0543L50.4371 43.7999L49.825 44.412L59.0795 53.6665L59.6916 53.0543Z"
                    fill="#354F57"/>
                  <circle cx="58" cy="58" r="57.5672" transform="rotate(-90 58 58)" stroke="#354F57" stroke-width="0.865672"/>
                </svg>
                <svg class="menu__item--icon mob" width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M31.3428 46.0004L31.3428 45.5004L30.8428 45.5004L30.8428 46.0004L31.3428 46.0004ZM51.1937 46.0004L51.1937 46.5004L52.4008 46.5004L51.5472 45.6469L51.1937 46.0004ZM31.3428 54.9556L30.8428 54.9556L30.8428 55.4556L31.3428 55.4556L31.3428 54.9556ZM51.1929 54.9556L51.5464 55.3092L52.4 54.4556L51.1929 54.4556L51.1929 54.9556ZM44.0041 62.1444L43.6505 61.7909L43.297 62.1444L43.6505 62.498L44.0041 62.1444ZM50.3364 68.4767L49.9828 68.8303L50.3364 69.1838L50.6899 68.8303L50.3364 68.4767ZM68.3355 50.4776L68.689 50.8312L69.0426 50.4776L68.689 50.1241L68.3355 50.4776ZM49.548 31.6902L49.9016 31.3366L49.548 30.9831L49.1945 31.3366L49.548 31.6902ZM43.2157 38.0225L42.8622 37.6689L42.5086 38.0225L42.8622 38.376L43.2157 38.0225ZM31.3428 46.5004L51.1937 46.5004L51.1937 45.5004L31.3428 45.5004L31.3428 46.5004ZM31.8428 54.9556L31.8428 46.0004L30.8428 46.0004L30.8428 54.9556L31.8428 54.9556ZM51.1929 54.4556L31.3428 54.4556L31.3428 55.4556L51.1929 55.4556L51.1929 54.4556ZM44.3576 62.498L51.5464 55.3092L50.8393 54.6021L43.6505 61.7909L44.3576 62.498ZM50.6899 68.1232L44.3576 61.7909L43.6505 62.498L49.9828 68.8303L50.6899 68.1232ZM67.9819 50.1241L49.9828 68.1232L50.6899 68.8303L68.689 50.8312L67.9819 50.1241ZM49.1945 32.0437L67.9819 50.8312L68.689 50.1241L49.9016 31.3366L49.1945 32.0437ZM43.5693 38.376L49.9016 32.0437L49.1945 31.3366L42.8622 37.6689L43.5693 38.376ZM51.5472 45.6469L43.5693 37.6689L42.8622 38.376L50.8401 46.354L51.5472 45.6469Z"
                    fill="#354F57"/>
                  <circle cx="50" cy="50" r="49.5" transform="rotate(-90 50 50)" stroke="#354F57"/>
                </svg>
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="menu__right">
          <div class="menu__post">
            <h4 class="menu__subtitle">{{ $t('menu.sayHi') }}</h4>
            <a class="menu__link" href="mailto:agency@inchkiev.ua">agency@inchkiev.ua</a>
          </div>
          <div class="menu__address">
            <h4 class="menu__subtitle">{{ $t('menu.address') }}</h4>
            <a href="https://goo.gl/maps/r56CmgBvRbF45VwG8"
               target="_blank" class="menu__link cursor-hover">
              {{ $t('menu.street[0]') }}
              <br>
              <span v-html="$t('menu.street[1]') "></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import ThemeToggle from '@/components/ThemeToggle.vue';
import LangsSelect from '@/components/LangsSelect.vue';

export default {
  name: 'customMenu',
  components: {
    ThemeToggle,
    LangsSelect,
  },
  computed: {
    ...mapGetters([
      'getOpenMenu',
      'getUserTheme',
      'getCurrentPage',
    ]),
  },
  methods: {
    ...mapMutations([
      'setOpenMenu',
      'setCurrentPage',
    ]),
    handleOpenMenu() {
      setTimeout(() => {
        this.setOpenMenu();
      }, 1000);
    },
    handleCurrentPage(pageName) {
      this.setCurrentPage(pageName);
      this.setOpenMenu();
    },
  },
};

</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.menu::-webkit-scrollbar { /* webkit */
  width: 0;
  height: 0;
}

.menu {
  scrollbar-width: none; /* firefox */
}

.menu {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  background-color: var(--background-color-primary);
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-left: 6%;
    padding-right: 0;
    padding-top: 28px;
  }

  &__theme-toggle {
    display: none;
    margin-right: 32px;
  }

  &__langs {
    display: none;
  }

  &__controls {
    display: flex;
  }

  &__close {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    height: 36px;
    // cursor: pointer;
    width: 390px;
    min-width: 390px;
    justify-content: center;

    svg {
      transform: rotate(45deg);
      z-index: 2;
      position: relative;

      path {
        stroke: var(--text-primary-color);
      }
    }

    &--text {
      margin-bottom: 0;
      margin-left: 11px;
      position: relative;
      z-index: 1;
      -webkit-tap-highlight-color: transparent;
    }

    &:hover {
      .menu__close--text::before {
        opacity: 1;
      }
    }
  }

  &__logo {
    max-width: 100%;
    width: 108px;
    object-fit: contain;

    path {
      stroke: var(--text-primary-color);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 62% 1fr;
    padding: 0 20%;
    height: 100%;
    align-items: center;
    // margin-top: 30px;
  }

  &__list {
    padding-left: 20%;
  }

  &__left {
    min-width: 720px;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 28px;
    user-select: none;
    max-width: fit-content;
    -webkit-tap-highlight-color: transparent;

    &--before {
      opacity: 0;
      color: #29F6D9;
      font-weight: 300;
      font-style: italic;
      position: absolute;
      width: fit-content;
      transform: translateX(-102%);
      bottom: 2px;
      left: -16px;
      font-size: 60px;
      line-height: 64px;
      transition: all .2s ease-in;
    }

    &--text {
      font-family: "TimesNewRoman-I";
      font-weight: 400;
      font-style: italic;
      font-size: 80px;
      line-height: 80px;
      color: #92A1AE;
      text-align: left;
      position: relative;
      -webkit-tap-highlight-color: transparent;

      &.activeMenuItem {
        color: var(--text-primary-color);

        &::before {
          content: '';
          width: 100%;
          height: 2px;
          background-color: #354F57;
          position: absolute;
          bottom: -8px;
          left: 0;
        }

        // margin-bottom: 20px;
      }
    }

    &.contact .menu__item--text.activeMenuItem::before {
      width: 74%;
    }

    &--icon {
      transform: translateY(34%);
      margin-left: 10px;

      &.mob {
        display: none;
      }

      path {
        fill: var(--menu-icon-color);
      }

      circle {
        stroke: var(--menu-icon-color);
      }
    }

    &:nth-of-type(2) {
      margin-bottom: 20px;
    }

    &:nth-of-type(3) {
      margin-bottom: 30px;
    }

    &:nth-of-type(4), &:nth-of-type(5) {
      margin-bottom: calc(28px + 18px);

      & > .menu__item--after {
        position: absolute;
        bottom: -30px;
        font-size: 24px;
        line-height: 36px;
        color: #92A1AE;
      }
    }

    &:last-of-type {
      margin-top: 80px;
    }

    &:hover {
      .menu__item {
        &--text {
          color: #29F6D9;

          &.activeMenuItem {
            &::before {
              background-color: #29F6D9;
            }
          }
        }

        &--icon {
          path,
          circle {
            stroke: #29F6D9;
          }
        }

        &--before {
          opacity: 1;
        }
      }
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #92A1AE;
    margin-bottom: 21px;
    -webkit-tap-highlight-color: transparent !important;
  }

  &__right {
    text-align: right;
    margin-bottom: 100%;
    margin-top: 60px;
  }

  &__post {
    margin-bottom: 102px;
  }

  &__link {
    line-height: 20px;
    -webkit-tap-highlight-color: transparent;

    text-decoration: none;
    background: linear-gradient(to right, rgba(100, 200, 200, 0), rgba(100, 200, 200, 0)),
    linear-gradient(to right, rgba(146, 161, 174, 1), rgba(146, 161, 174, 1));
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    @media screen and (min-width: 1023px) {
      &:hover {
        background-size: 0 1px, 100% 1px;
      }
    }

    &:active {
      background-size: 0 1px, 100% 1px;
    }
  }
}

@media screen and (max-width: 1630px) {
  .menu {
    &__content {
      padding-left: 314px;
      padding-right: 140px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .menu {
    &__close {
      width: 340px;
      min-width: 340px;
      height: 19px;
    }
  }
}

@media screen and (max-width: 1220px) {
  .menu {
    &__content {
      // grid-template-columns: 1fr;
      // padding-left: 6%;
      display: flex;
      flex-direction: column;
      align-items: unset;
    }

    &__right {
      text-align: left;
      padding-left: 20%;
      margin-top: 103px;
      margin-bottom: 50px;
      transform: translateY(-10%);
    }

    &__post {
      margin-bottom: 66px;
    }

    &__address {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1060px) {
  .menu {
    &__content {
      padding-left: 280px;
    }
  }
}

@media screen and (max-width: 960px) {
  .menu {
    &__content {
      padding-left: 218px;
    }

    &__item {
      margin-bottom: 16px;

      &--text {
        font-size: 66px;
        line-height: 64px;
      }

      &--before {
        font-size: 50px;
        line-height: 64px;
        display: none;
      }

      &--icon {
        &.desc {
          display: none;
        }

        &.mob {
          display: inline-block;
        }
      }

      &:nth-of-type(4), &:nth-of-type(5) {
        .menu__item--after {
          font-size: 20px;
          line-height: 28px;
        }
      }

      &:last-of-type {
        margin-top: 44px;

        .menu__item--text {
          font-size: 80px;
          line-height: 80px;
        }
      }
    }

    &__post {
      margin-bottom: 32px;
    }

    &__subtitle {
      margin-bottom: 14px;
    }

    &__logo {
      max-width: 84px;
      width: 100%;
    }

    &__list {
      padding-left: 0%;
    }

    &__right {
      padding-left: 0%;
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    &__content {
      padding-left: 44px;
      padding-right: 36px;
    }

    &__item {
      margin-bottom: 16px;

      // &--text {
      //   font-size: 66px;
      //   line-height: 64px;
      // }

      &--before {
        font-size: 28px;
        line-height: 40px;
      }

      &--icon {
        transform: translateY(30%);
      }

      img {
        max-width: 65px;
      }

      &:last-of-type {
        margin-top: 54px;

        .menu__item--text {
          margin-right: 0;
        }
      }

      &:nth-of-type(4) {
        .menu__item--after {
          font-size: 20px;
          line-height: 28px;
        }
      }

      svg {
        max-width: 100px;
        margin-left: 0px;
      }
    }

    &__right {
      margin-top: 36px;
      // padding-left: 14%;
    }

    &__left {
      min-width: unset;
      margin-bottom: 0;
    }

    &__post {
      margin-bottom: 28px;
    }

    &__list {
      padding-left: 14%;
      padding-right: 0;
    }

    &__close {
      width: 160px;
      min-width: 160px;

      &--text {
        &::before {
          left: -43px;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .menu {
    &__header {
      padding-top: 16px;
      padding-left: 16px;
      padding-bottom: 2px;
      // justify-content: flex-start;
    }

    &__item {
      &--text {
        font-size: 44px;
        line-height: 44px;
      }

      &:last-of-type {
        .menu__item--text {
          font-size: 44px;
          line-height: 44px;
        }
      }
    }

    &__content {
      margin-top: 18px;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__logo {
      max-width: 54px;
      height: 54px;
      width: 54px;
    }

    &__theme-toggle {
      display: flex;
      align-items: flex-start;
      margin-left: 18px;
      margin-right: 0;
    }

    &__langs {
      display: block;
      // margin-left: 30px;
    }

    &__list {
      padding-left: 33%;
    }

    &__right {
      padding-left: 33%;
    }

    &__close {
      width: 40px;
      min-width: 40px;
      margin-top: 8px;
      margin-right: 16px;

      &--text {
        display: none;
      }
    }

    &__controls {
      max-width: fit-content;
      margin-left: 12%;
      margin-top: 8px;
    }

    &__address {
      & > .menu__link > br {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .menu {
    &__list {
      padding-left: 42px;
    }

    &__right {
      padding-left: 42px;
    }

    &__left {
      padding-left: 53px;
    }

    &__item {
      &--before {
        display: none;
      }

      &--icon {
        &.mob {
          max-width: 65px;
        }
      }

      &:active {
        .menu__item--text {
          color: #29F6D9;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .menu {
    &__list {
      padding-left: 0px;
      padding-right: 0;
    }

    &__right {
      padding-left: 0px;
      max-width: 100%;
      padding-right: 4px;
    }

    &__link {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 380px) {
  .menu {
    &__left {
      padding-left: 2px;
    }

    &__controls {
      margin-left: 4%;
    }

    &__item {
      &:last-of-type {
        margin-top: 8px;
      }
    }
  }
}
</style>
